import React, {CSSProperties, ReactNode} from 'react';
import './App.css';

import { Container } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

import { Youtube, H3, H2, Caption, VSpacer, useWindowSize, useIsSp } from "./Utils"
import { isJSDocNullableType } from 'typescript';

function MainTitle() {
  const isSp = useIsSp()

  const style: CSSProperties = {
    fontFamily: "TitleFont",
    fontWeight: 900,
    fontSize: isSp ? 42 : 80,
    lineHeight: "1em",
  }

  return <Container style={style}>
    <div>TOKYO 2020</div>
    <div>TELEPORTATION</div>

    <VSpacer height={16}></VSpacer>

    <div style={{fontSize: isSp ? 16 : 40, lineHeight: "1em"}}>
      Super-realistic remote viewing of sailing
    </div>
    <div style={{fontSize: isSp ? 16 : 40, lineHeight: "1em"}}>
      with 5G and ultra-wide vision ship
    </div>
  </Container>
}

function MainVideo() {
  return <Container>
    <Youtube id={"LmjK1tdziOU"}></Youtube>
  </Container>
}


function Title(props: {children: ReactNode}) {
  return <div style={{
    fontFamily: "TitleFont",
    fontWeight: 900,
    fontSize: 40,
  }}>{props.children}</div>
}

function SubTitle(props: {children: ReactNode}) {
  return <div style={{
    fontFamily: "TitleFont",
    fontWeight: 900,
    fontSize: 24,
  }}>{props.children}</div>
}

function Text(props: {children: ReactNode}) {
  return <div style={{
    // fontFamily: "TitleFont",
    fontSize: 16,
    lineHeight: "1.4em",
  }}>{props.children}</div>
}

function Problem() {
  return <Container>
    <Title>PROBLEM</Title>
    <VSpacer height={8}/>
    <Text>
      Due to the nature of the sport, watching athletes compete offshore in the TOKYO 2020 sailing event was challenging at the very least. NTT leveraged 5G and Ultra-realistic Communication Technology to create an innovative and highly realistic spectating style
    </Text>
    <VSpacer height={16}/>
  </Container>;
}

function Challenge() {
  return <Container>
    <Title>CHALLENGE</Title>
    <VSpacer height={8}/>
    <Text>
      NTT leveraged 5G and Ultra-realistic Communication Technology to display real-time sailing images on a 55-meter wide-vision offshore screen. NTT created an innovative and highly realistic spectating style allowing fans to experience viewing the races as if enjoying premium seats aboard a cruise ship. Drones and boats equipped with multiple 4K cameras transmitted recorded video in real time in a 5G high-capacity format. Ultra-wide images with 12K horizontal resolution were synthesized in real time and displayed in wide vision on gigantic 55m offshore screens.
    </Text>
  </Container>;
}

function Result() {
  const images = [
    "result-01@8x-50.jpg",
    "result-02@8x-50.jpg",
    "result-03@8x-50.jpg",
    "result-04@8x-50.jpg",
  ]

  return <Container>
    <Title>RESULT</Title>
    <VSpacer height={4}/>
    <Text>
      Met with high expectations, NTT’s initiative was broadly covered by media outlets such as the BBC, NBCU, the Wall Street Journal, and Bloomberg, representing an advertising cost conversion of 420 million yen (US$3.4 million). The results received high praise from Olympic and Paralympic athletes and affiliated parties.
    </Text>

    <VSpacer height={32}/>

    { images.map((filename, i) => 
      <img style={{width: "50%", marginBottom: 8}} src={"images/" + filename} />
    ) }

  </Container>;
}

function Technology() {
  const techImages = [
    "tech-01@8x-50.jpg",
    "tech-02@8x-50.jpg",
    "tech-03@8x-50.jpg",
    "tech-04@8x-50.jpg",
  ]

  const ultraWideImages = [
    "ultra-wide-02@8x-50.jpg",
    "ultra-wide-01@8x-50.jpg",
  ]

  return <Container>
    <Title>TECHNOLOGY</Title>
    <VSpacer height={8}/>
    <Text>
      The competition was shot with four 4K cameras lined up to generate a horizontal image of 12K resolution. With the parallax differences between the cameras, simply joining the images together would result in splicing misalignments instead of producing a single, clear 12K image.  NTT succeeded in creating the world’s first Ultra-wide Video-synthesis Technology that performs advanced composition processing in real time, producing beautifully spliced video. The ultra-wide 12K video was displayed on a 55m LED screen floating on the ocean, magically blending in with the natural ocean and sky surroundings for an enhanced remote viewing experience with a high sense of presence.
    </Text>

    <VSpacer height={16} />

    { techImages.map((filename, i) => 
      <img style={{width: "25%", marginBottom: 8}} src={"images/" + filename} />
    ) }

    <VSpacer height={32} />

    <SubTitle>Ultra-realistic Communication Technology </SubTitle>
    <VSpacer height={8}/>
    <Text>
      By combining multiple 4K camera images in real time, this technology generates wide images with high resolution and a wide viewing angle that cannot be captured by a single camera. The point of this technology is to derive and synthesize a composite plane of images between cameras in real time. To derive the composite plane, a two-step search is performed: first, a rough search is performed on the reduced video frames, and then a detailed search is performed on the range derived in that process. This makes it possible to generate high-quality composite images in real time. In addition, various types of video are processed in parallel on the GPU, resulting in a significant increase in speed.
    </Text>

    <VSpacer height={32} />

    <img style={{width: "100%"}} src="images/ultra-wide-01@8x-50.jpg" />
    <div style={{textAlign: "center"}}>
      <img style={{width: "50%"}} src="images/ultra-wide-02@8x-50.jpg" />
    </div>

  </Container>;
}


function App() {
  const size = useWindowSize()

  const style: CSSProperties = {
    backgroundColor: "white",
    minHeight: size.height,
    color: '#1087b1',
    overflow: 'hidden',
  }

  return (
    <div style={style}>
      <VSpacer height={60} />
      <MainTitle></MainTitle>
      <VSpacer height={40} />
      <MainVideo></MainVideo>

      <VSpacer height={20} />

      <Problem />
      <VSpacer height={40} />

      <Challenge />
      <VSpacer height={40} />

      <Result />
      <VSpacer height={40} />

      <Technology />
      <VSpacer height={40} />

    </div>
  );
}

export default App;
